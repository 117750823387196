//Import Modules
import React, { Component } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
    Button,
    Table
} from 'reactstrap';

//Import Files
import AuthService from '../../services/AuthService';
import StockDetailsService from '../../services/StockDetailsService';
import CanvasJSReact from '../../assets/pluginFiles/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            StockDocument: {
                overview: {},
                logo: ''
            },
            dataPoints1: [],
            dataPoints2: [],
            dataPoints3: [],
            isLoaded: false,
            inWatchlist: null,
            stockID: null,
            isAuth: null
        }
    }

    async componentDidMount() {
        let symbol = this.props.match.params.id;

        window.gtag("event", "screen_view", {
            'screen_name': 'Details - ' + symbol
        });

        await this.GetDetails(symbol);
        this.Watchlist();
    }

    async Watchlist() {
        await AuthService.isAuthenticated().then(data => {
            this.setState({ isAuth: data.isAuthenticated })
            if (data.isAuthenticated === true) {
                const dataPass = { username: data.user.username, id: this.state.stockID };
                StockDetailsService.itemInWatchlist(dataPass).then(res => {
                    this.setState({ inWatchlist: res })
                })
            }
        })
    }

    async GetDetails(symbol) {
        await StockDetailsService.getStockDetails(symbol).then(data => {
            var dps1 = [], dps3 = [];
            for (var i = 0; i < data.historicalData.length; i++) {
                dps1.push({
                    x: new Date(data.historicalData[i].date),
                    y: [
                        Number(data.historicalData[i].open),
                        Number(data.historicalData[i].high),
                        Number(data.historicalData[i].low),
                        Number(data.historicalData[i].close)
                    ]
                });
                dps3.push({ x: new Date(data.historicalData[i].date), y: Number(data.historicalData[i].close) });
            }
            this.setState({
                isLoaded: true,
                dataPoints1: dps1,
                dataPoints3: dps3,
                StockDocument: data,
                stockID: data._id
            });
        })
    }

    resizeHandler = () => {
        this.child.resizeHandler();
        window.dispatchEvent(new Event('resize'));
    }

    render() {

        CanvasJS.addColorSet("colourArray", [
            "#0288D1",
            "#008000",
        ]);

        const options = {
            theme: "light2",
            title: {
                text: "StockChart"
            },
            subtitles: [{
                text: "Price-Volume Trend"
            }],
            colorSet: "colourArray",
            rangeSelector: {
                enabled: true,
                buttons: [
                    {
                        label: "6Month",
                        range: 6,
                        rangeType: "month"
                    },
                    {
                        label: "1Year",
                        range: 1,
                        rangeType: "year"
                    },
                    {
                        label: "5Year",
                        range: 5,
                        rangeType: "year"
                    },
                    {
                        label: "All",
                        rangeType: "all"
                    }
                ]
            },
            charts: [{
                axisX: {
                    lineThickness: 5,
                    tickLength: 0,
                    labelFormatter: function (e) {
                        return "";
                    },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true,
                        labelFormatter: function (e) {
                            return "";
                        }
                    }
                },
                axisY: {
                    title: "Price",
                    prefix: "$",
                    tickLength: 0
                },
                toolTip: {
                    shared: true
                },
                data: [{
                    name: "Price (in USD)",
                    yValueFormatString: "$#,###.##",
                    type: "candlestick",
                    dataPoints: this.state.dataPoints1
                }]
            }],
            navigator: {
                data: [{
                    dataPoints: this.state.dataPoints3
                }],
                slider: {
                    minimum: new Date("2000-01-01"),
                    maximum: new Date("2020-01-01")
                }
            }
        };

        const containerProps = {
            width: "100%",
            height: "450px",
            margin: "auto"
        };

        const onSubmitAdd = e => {
            e.preventDefault();
            AuthService.isAuthenticated().then(data => {
                if (data.isAuthenticated === true) {
                    const dataPass = { username: data.user.username, id: this.state.stockID };
                    StockDetailsService.addWatchList(dataPass)
                }
            })
            this.Watchlist(this.state.stockID);
        }

        const onSubmitRemove = e => {
            e.preventDefault();
            AuthService.isAuthenticated().then(data => {
                if (data.isAuthenticated === true) {
                    const dataPass = { username: data.user.username, id: this.state.stockID };
                    StockDetailsService.removeWatchList(dataPass)
                }
            })
            this.Watchlist(this.state.stockID);
        }

        const AddtoWL = () => {
            return (
                <Form onSubmit={onSubmitAdd}>
                    <Button color="success" className="float-left btn btn-success btn-block" type="submit">Add To Watchlist</Button>
                </Form>
            )
        }

        const RemovefromWL = () => {
            return (
                <Form onSubmit={onSubmitRemove}>
                    <Button color="danger" className="float-left btn btn-danger btn-block" type="submit">Remove From Watchlist</Button>
                </Form>
            )
        }

        let WLButton;
        if (this.state.isAuth === true) {
            if (this.state.inWatchlist === true) {
                WLButton = <Form onSubmit={onSubmitRemove}><Button color="danger" className="float-left btn btn-danger btn-block" type="submit">Remove From Watchlist</Button></Form>
            }
            else {
                WLButton = <Form onSubmit={onSubmitAdd}><Button color="success" className="float-left btn btn-success btn-block" type="submit">Add To Watchlist</Button></Form>
            }
        }
        else {
            WLButton = null;
        }

        if (this.state.StockDocument.logo !== '') {
            var companyImage = <img width="20px" src={this.state.StockDocument.logo} alt="logo" className="img-fluid float-left" />;
        }
        else {
            var companyImage = '';
        }

        return (
            <div>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col className="pt-2">
                                        <CardTitle>{companyImage}&nbsp; Stock Chart For {this.state.StockDocument.name}</CardTitle>
                                    </Col>

                                    <Col xs="12" sm="4" md="4" lg="4" xl="4">
                                        {WLButton}
                                        {/* {!this.state.inWatchlist ? AddtoWL() : RemovefromWL()} */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            this.state.isLoaded &&
                                            <CanvasJSStockChart containerProps={containerProps} options={options} />
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Card>
                            <CardBody>
                                <CardTitle>{companyImage}&nbsp; Stock Details For {this.state.StockDocument.name}</CardTitle>
                                <hr />
                                <Row>
                                    <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                        <CardSubtitle>Description</CardSubtitle>
                                        <CardText>{this.state.StockDocument.overview.Description}</CardText>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                        <CardSubtitle>Details</CardSubtitle>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Name</th>
                                                    <td>{this.state.StockDocument.overview.Name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Symbol</th>
                                                    <td>{this.state.StockDocument.overview.Symbol}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Asset Type</th>
                                                    <td>{this.state.StockDocument.overview.AssetType}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Country</th>
                                                    <td>{this.state.StockDocument.overview.Country}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Currency</th>
                                                    <td>{this.state.StockDocument.overview.Currency}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Exchange</th>
                                                    <td>{this.state.StockDocument.overview.Exchange}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Industry</th>
                                                    <td>{this.state.StockDocument.overview.Industry}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Sector</th>
                                                    <td>{this.state.StockDocument.overview.Sector}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h1></h1>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Details;