import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
    Nav,
    Navbar,
    NavbarBrand,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';

import { AuthContext } from '../../context/AuthContext';

import DefaultAvatar from '../../assets/images/default-avatar.png';
import Logo from '../../assets/images/logo.png';

const Header = (props) => {
    const { isAuthenticated } = useContext(AuthContext);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {

        const updateDimensions = () => {
            setWidth(window.innerWidth)

            if (width >= 768) {
                document.getElementById('user-icon').classList.add('ml-auto');
            } else {
                document.getElementById('user-icon').classList.remove('ml-auto');
            }

            if (width <= 768 && isAuthenticated === false) {
                document.getElementById('logo-div').classList.add('margin-right-31');
            } else {
                document.getElementById('logo-div').classList.remove('margin-right-31');
            }

        }
        if (document.readyState === "complete") {
            updateDimensions();
        }
        window.addEventListener("resize", updateDimensions.bind(this));
        window.addEventListener("load", updateDimensions.bind(this));
        return () => {
            window.removeEventListener("load", updateDimensions.bind(this));
            window.removeEventListener("resize", updateDimensions.bind(this));
        };
    }, [width]);

    const showMobilemenu = () => {
        document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    const showDropDown = () => {
        return (
            <>
                <DropdownToggle nav caret className="pro-pic">
                    <img
                        src={DefaultAvatar}
                        alt="user"
                        className="rounded-circle"
                        width="31"
                    />
                </DropdownToggle>
                <DropdownMenu right className="user-dd">

                    <Link to="/account">
                        <DropdownItem>
                            <i className="fa fa-user mr-1 ml-1" /> My Account
                        </DropdownItem>
                    </Link>

                    <DropdownItem divider />

                    <Link to="/logout">
                        <DropdownItem>
                            <i className="fa fa-power-off mr-1 ml-1" /> Logout
                        </DropdownItem>
                    </Link>
                </DropdownMenu>
            </>
        )
    }

    return (
        <header className="topbar navbarbg" data-navbarbg="skin1">
            <Navbar className="top-navbar" dark expand="md">
                <div>
                    <button className="btn-link nav-toggler d-block d-md-none" onClick={() => showMobilemenu()}>
                        <i className="ti-menu ti-close" />
                    </button>
                </div>
                <div id="logo-div">
                    <NavbarBrand href="/" className="mx-auto">
                        <b className="logo-icon">
                            <img
                                src={Logo}
                                alt="logo"
                                className="img-fluid"
                                width="150px"
                            />
                        </b>
                    </NavbarBrand>
                </div>
                <div id="user-icon">
                    <Nav className="float-right" navbar>
                        <UncontrolledDropdown nav inNavbar>
                            {isAuthenticated ? showDropDown() : null}
                        </UncontrolledDropdown>
                    </Nav>
                </div>
            </Navbar>
        </header>
    );
}
export default Header;
