export default {
    addStock: stock => {
        //Check Detials
        console.log(stock)
        return fetch('/Admin/addStock', {
            method: "post",
            body: JSON.stringify(stock),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then(data => data);
    }
}