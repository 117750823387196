import React, { useContext, useState, useEffect } from 'react';

import { AuthContext } from '../context/AuthContext';
import AuthService from '../services/AuthService';
import AlertService from '../services/AlertService';
import LiveDataService from '../services/LiveDataService';

const AlertScript = (props) => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const [alertList, setAlertList] = useState([]);
    const [oldAlert, setOldAlert] = useState(null);
    const [newAlert, setNewAlert] = useState(null);
    const [bool, setBool] = useState(false);


    const SendAlert = async (alert) => {
        alert(alert.message);
    }

    const FetchAlertData = async () => {
        const symbols = [];

        console.log(isAuthenticated);
        if (isAuthenticated === true) {
            const dataPass = { username: user.username };
            await AlertService.getAlerts(dataPass).then(res => {
                setAlertList(res.alerts);
            })

            for (let i = 0; i < alertList.length; i++) {
                symbols[i] = alertList[i].stock;
            }

            const datapass = { symbol : symbols}
            console.log(datapass);

            LiveDataService.liveDataSymbols(datapass).then(data => {
                setOldAlert(newAlert);
                setNewAlert(data);
            });

            console.log(alertList)
            console.log(newAlert)
            console.log(oldAlert)

            for (let i = 0; i < alertList.length; i++) {
                if(alertList[i].price < newAlert[i].liveData.priceCurrent && alertList[i].price > oldAlert[i].liveData.priceCurrent || alertList[i].price > newAlert[i].liveData.priceCurrent && alertList[i].price < oldAlert[i].liveData.priceCurrent) {
                    SendAlert(alertList[i]);
                }
            }

        }
    }

    //useEffect(() => { FetchAlertData() }, [isAuthenticated]);
    useEffect(() => {
        console.log(bool)
        if(bool === false) {
            FetchAlertData()
            setBool(true)
            setInterval(FetchAlertData, 20000);
        }
        else {
            setInterval(FetchAlertData, 20000);
        }
    }, [])

    return (
        <>
        </>
    )
}

export default AlertScript;