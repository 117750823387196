//Import Modules
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Table,
    Form,
    Row,
    Col,
    Button
} from 'reactstrap';

//Import Files
import AuthService from '../../services/AuthService';
import AlertService from '../../services/AlertService';
import Message from './message';

const CurrentAlerts = (props) => {
    const [alertList, setAlertList] = useState();
    const [message, setMessage] = useState(null);

    const fetchData = async () => {
        await AuthService.isAuthenticated().then(data => {
            if (data.isAuthenticated === true) {
                const dataPass = { username: data.user.username };
                AlertService.getAlerts(dataPass).then(res => {
                    setAlertList(res.alerts);
                })
            } else {

            }
        })
    }

    const onSubmitDelete = data => e => {
        e.preventDefault();
        const datapass = {
            id: data
        }
        console.log(datapass)

        AlertService.remove(datapass).then(data => {
            setMessage(message);
        });

        fetchData();
    }

    useEffect(() => { fetchData() }, []);

    return (
        <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">
                <i className="fa fa-bell"> </i>
                <span> Current Alerts</span>
            </CardTitle>
            <CardBody className="">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Stock</th>
                            <th>Price</th>
                            <th>Message</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {alertList && alertList.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <th>{data.stock}</th>
                                    <td>£{data.price}</td>
                                    <td>{data.message}</td>
                                    <td>
                                        <Form onSubmit={onSubmitDelete(data.id)}>
                                            <Row>
                                                <Col>
                                                    <Button color="danger" className="btn btn-sm btn-block" type="submit">Remove</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <Row>
                    <Col>
                    {message ? <Message message={message} /> : null}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default CurrentAlerts;