export default {
    newsData: () => {
        return fetch('https://finnhub.io/api/v1/news?category=general&token=bubuskn48v6ob2eo15pg')
            // .then(res => {
            //     const news = [];
            //     for (let i = 0; i < 4; i++) {
            //         news[i] = res.data[i]
            //     }
            // })
            .then(res => res.json())
            .then(data => {
                const news = [];
                for (let i = 0; i < 4; i++) {
                    news[i] = data[i]
                }
                return news
            });
    }
}