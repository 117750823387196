import React from 'react';
import {
    Row,
    Col
} from 'reactstrap';

const SearchBar = ({ input: keyword, onChange: setKeyword }) => {
    return (
        <Row>
            <Col xs="12" sm="12" md="8" lg="8" xl="6">
                <input type="text" className="form-control" placeholder="Search for Stock" onChange={(e) => setKeyword(e.target.value)} value={keyword} />
            </Col>
        </Row>
    );
}

export default SearchBar