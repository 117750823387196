import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Card,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    Row,
    Col
} from 'reactstrap';

import LiveDataService from '../../services/LiveDataService';
import AuthService from '../../services/AuthService';
import News from './news';
import Trends from './trends';

const Starter = () => {
    const [stocks, setStocks] = useState();
    const [bool, setBool] = useState(false);

    //const timer = ms => new Promise(res => setTimeout(res, ms))

    //Get Watchlist items with live data
    //map live data out in cards
    const fetchData = async () => {
        console.log('init');

        await AuthService.isAuthenticated().then(async function tempfunct(data) {
            if (data.isAuthenticated === true) {
                const dataPass = { username: data.user.username };
                LiveDataService.liveData(dataPass).then(res => {
                    console.log('in1');
                    setStocks(res.stocks);
                    console.log('in2');
                }).catch(err => console.log(err))
            } else {
                await LiveDataService.liveDataNoLogin().then(res => {
                    console.log(res)
                    setStocks(res.stocks);
                }).catch(err => console.log(err))
            }
        })
    }

    // const newFunc = async () => {
    //     console.log('func')
    //     if(bool === false) {
    //         console.log('fal')
    //         fetchData()
    //         setBool(true)
    //     }
    //     else {
    //         //await timer(20000);
    //         //fetchData(); 
    //     }
        
        
    // }

    //newFunc();


    useEffect(() => {

        window.gtag("event", "screen_view", {
            'screen_name': 'Dashboard'
        });

        console.log(bool)
        console.log('func')
        if(bool === false) {
            console.log('fal')
            fetchData()
            setBool(true)
            setInterval(fetchData, 20000);
        }
        else {
            setInterval(fetchData, 20000);
        }
    }, [])

    return (
        <div>
            <Row>
                <Col>
                    <h1>Watchlist</h1>
                    <hr />
                </Col>
            </Row>
            <Row className="dashboard-cards">
                {stocks && stocks.map((data, index) => {

                    var cssColourVarable = '';
                    var companyImage = '';

                    if (Number(data.liveData.priceChange) > 0) {
                        cssColourVarable = 'card-colour-green'
                    }
                    else {
                        cssColourVarable = 'card-colour-red'
                    }

                    if (data.logo !== '') {
                        companyImage = <img key={`${index}image`} width="40px" src={data.logo} alt="logo" className="img-fluid float-right" />;
                    }
                    else {
                        companyImage = '';
                    }

                    var dateTime = new Date(data.liveData.priceDateTime * 1000);
                    var year = dateTime.getFullYear();
                    var month = dateTime.getMonth();
                    var day = dateTime.getDate();
                    var hours = dateTime.getHours();
                    var minutes = dateTime.getMinutes();

                    if (minutes < 10)
                        minutes = "0" + minutes;

                    var FormattedDateTime = hours + ":" + minutes + " " + day + "/" + month + "/" + year;

                    return (
                        <Col xs="12" sm="6" md="4" key={index}>
                            <Link to={{ pathname: `/details/${data.symbol}`, data: data.symbol }} key={`${index}link`}>
                                <Card key={index} className={cssColourVarable}>
                                    <CardBody key={index}>
                                        <Row key={`${index}row`}>
                                            <Col key={`${index}col1`}>
                                                <CardTitle key={`${index}title`}>{data.symbol}</CardTitle>
                                                <CardSubtitle key={`${index}subtitle`}>{FormattedDateTime}</CardSubtitle>
                                            </Col>
                                            <Col key={`${index}col2`}>
                                                {companyImage}
                                            </Col>
                                        </Row>
                                        <CardText key={`${index}card`}>
                                            <span key={`${index}span1`} className="float-left">£{data.liveData.priceCurrent}</span>
                                            <span key={`${index}span2`} className="float-right">{data.liveData.priceChange} ({data.liveData.pricePercentChange}%)</span>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    )
                })}
            </Row>
            <Row>
                <Col>
                    <h1>Trends</h1>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Trends />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>News</h1>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <News />
                </Col>
            </Row>
        </div>
    );
}

export default Starter;
