import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col
} from 'reactstrap';

import SearchBar from './searchBar';
import SearchList from './searchList';
import StockDetailsService from '../../services/StockDetailsService';

const SearchPage = (props) => {
    const [input, setInput] = useState('');
    const [stockListDefault, setStockListDefault] = useState();
    const [stockList, setStockList] = useState([]);

    const fetchData = async () => {
        return await StockDetailsService.search().then(data => {
            setStockList(data)
            setStockListDefault(data)
        })
    }

    const updateInput = async (input) => {
        const filtered = stockListDefault.filter(stock => {
            return stock.name.toLowerCase().includes(input.toLowerCase())
        })
        setInput(input);
        setStockList(filtered);
    }

    useEffect(() => {

        window.gtag("event", "screen_view", {
            'screen_name': 'Search'
        });

        fetchData()
    }, []);

    return (
        <Row>
            <Col>
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        <i className="fa fa-search"> </i>
                        <span> Stocks</span>
                    </CardTitle>
                    <CardBody>
                        <h2>Stock Search</h2>
                        <SearchBar input={input} onChange={updateInput} />
                        <h3 className="pt-3">Stock List</h3>
                        <SearchList stockList={stockList} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default SearchPage