import React from 'react';


const Footer = () => {
    return (
        <footer className="footer text-center">
            Cieran Hughes -- Stock Tracker 4.1.0
        </footer>
    );
}
export default Footer;
