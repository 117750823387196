export default {
    addAlert: alert => {
        //Check Detials
        console.log(alert)
        return fetch('/Alert/addAlert', {
            method: "post",
            body: JSON.stringify(alert),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => data);
    },
    getAlerts: (data) => {
        return fetch('/Alert/alertlistItems')
            .then(res => res.json())
            .then(data => data);
    },
    remove: (data) => {
        return fetch('/Alert/remove', {
            method: "post",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => data);
    }
}

