import Starter from '../views/starter/starter.jsx';

//Components
import Login from '../views/ui-components/login';
import Register from '../views/ui-components/register';
import Logout from '../views/ui-components/logout';
import SearchPage from '../views/ui-components/searchPage.jsx';
import Details from '../views/ui-components/details.jsx';
import Alerts from '../views/ui-components/alerts.jsx';
import Admin from '../views/ui-components/admin.jsx';
import Account from '../views/ui-components/account.jsx';
import Info from '../views/ui-components/info';

var ThemeRoutes = [
  { 
    path: '/dashboard', 
    name: 'Dashboard', 
    icon: 'fa fa-home', 
    auth: 'all',
    component: Starter 
  },
  {
    path: '/alerts',
    name: 'Alerts',
    icon: 'fa fa-bell',
    auth: 'loggedin',
    component: Alerts
  },
  {
    path: '/search',
    name: 'Search',
    icon: 'fa fa-search',
    auth: 'all',
    component: SearchPage
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'fa fa-user',
    auth: 'none',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'fa fa-user-plus',
    auth: 'none',
    component: Register
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: 'fa fa-times-circle',
    auth: 'loggedin',
    component: Logout
  },
  {
    path: '/admin',
    name: 'Admin',
    icon: 'fa fa-shield-alt',
    auth: 'admin',
    component: Admin
  },
  {
    path: '/account',
    name: 'Account',
    icon: 'fa fa-user',
    auth: 'loggedin',
    component: Account
  },
  {
    path: '/info',
    name: 'Info',
    icon: 'fa fa-info-circle',
    auth: 'all',
    component: Info
  },
  {
    path: '/details/:id',
    name: 'Details',
    icon: 'fa fa-info-circle',
    component: Details
  },
  {
    path: '/',
    name: 'Starter',
    component: Starter
  },

];

export default ThemeRoutes;
