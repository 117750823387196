import $ from 'jquery';

export default {
	liveData: (userData) => {
		return fetch('/LiveData/wathlistItems')
			.then(res => res.json())
			.then(data => { return data })
			.catch(err => { return err })
	},
	liveDataNoLogin: () => {
		return fetch('/LiveData/wathlistItemsNoLogin')
			.then(res => res.json())
			.then(data => data)
			.catch(err => { return err })
	},
	allLiveData: () => {
		return fetch('/LiveData/all')
			.then(res => res.json())
			.then(data => { return data })
			.catch(err => { return err })
	},
	// liveData: (userData) => {
	//     return new Promise(function (resolve, reject) {
	//         $.ajax({
	//             cache: true,
	//             type: "Get",
	//             url: "/LiveData/wathlistItems",
	//             data: userData,
	//             contentType: "application/json; charset=utf-8",
	//             dataType: "json",
	//             success: (res) => resolve(res),
	//             error: (err) => reject(err)
	//         });
	//     });
	// },
	// liveDataNoLogin: () => {
	//     return new Promise(function (resolve, reject) {
	//         $.ajax({
	//             cache: true,
	//             type: "Get",
	//             url: "LiveData/wathlistItemsNoLogin",
	//             contentType: "application/json; charset=utf-8",
	//             dataType: "json",
	//             success: (res) => resolve(res),
	//             error: (err) => reject(err)
	//         });
	//     });
	// },
	// allLiveData: () => {
	//     return new Promise(function (resolve, reject) {
	//         $.ajax({
	//             cache: true,
	//             type: "Get",
	//             url: "/LiveData/all",
	//             contentType: "application/json; charset=utf-8",
	//             dataType: "json",
	//             success: (res) => resolve(res),
	//             error: (err) => reject(err)
	//         });
	//     });
	// },
	liveDataSymbols: (symbols) => {
		return fetch('/LiveData/liveDataSymbols', {
            method: "post",
            body: JSON.stringify(symbols),
            headers: {
                'Content-Type': 'application/json'
            }
        })
		.then(res => res.json())
		.then(data => { return data })
		.catch(err => { return err })
	}
}