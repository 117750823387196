export default {
    //Login
    login: user => {
        //Check Detials
        return fetch('/User/login', {
            method: "post",
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.status !== 401)
                    return res.json().then(data => data); //Log in correst
                else
                    return { isAuthenticated: false, user: { username: "", role: "" }, message: { msgBody: "Incorrect login details", msgError: true } }; //Failed
            })
            .catch(res => {
                return { isAuthenticated: false, user: { username: "", role: "" }, message: { msgBody: "Incorrect login details", msgError: true } }; //Failed
            });
    },
    //Register
    registerUser: user => {
        //Check Detials
        return fetch('/Admin/register', {
            method: "post",
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => data);
    },
    //Register
    register: user => {
        //Check Detials
        return fetch('/User/register', {
            method: "post",
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => data);
    },
    logout: () => {
        return fetch('/User/logout')
            .then(res => res.json())
            .then(data => data);
    },
    logoutDeleteAccount: () => {
        return fetch('/User/logoutDeleteAccount')
            .then(res => res.json())
            .then(data => data);
    },
    //check user authentication
    isAuthenticated: () => {
        return fetch('/User/authenticated')
            .then(res => {
                //console.log(res.status)
                if (res.status !== 401)
                    return res.json().then(data => data); //is authentacted
                else
                    return { isAuthenticated: false, user: { username: "", role: "" } }; //failed
            });
    }
    // passChange: (user) => {
    //     return fetch('User/passwordChange'), {
    //     method: "post",
    //         body: JSON.stringify(user),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     }
    //     .then(res => res.json())
    //     .then(data => data);
    // }
    // isAuthenticated: () => {
    //     return fetch('/User/authenticated')
    //         .then(res => {
    //             return res.json().then(data => data); //is authentacted
    //         });
    // }
}