//Import Modules
import React, { createContext, useState, useEffect } from 'react';
import AuthService from '../services/AuthService';
import {
    Card,
    CardTitle,
    Row,
    Col,
    CardBody
} from 'reactstrap';

export const AuthContext = createContext();

export default ({ children }) => {
    //Set Context Varables
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        AuthService.isAuthenticated().then(data => {
            setUser(data.user);
            setIsAuthenticated(data.isAuthenticated);
            setIsLoaded(true);
        });
    }, []);

    const Loading = () => {
        return (
            <Row className="justify-content-center">
                <Col className="mt-2" xs="10" sm="8" md="6" lg="6" xl="6">
                    <Card>
                        <CardBody>
                            <CardTitle className="text-center">The server has been sleeping, waking up now</CardTitle>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    return (
        <div>
            {!isLoaded ? Loading() :
                <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated }}>
                    {children}
                </AuthContext.Provider>}
        </div>
    )
}