//Import Modules
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Label,
    Button,
    Input,
    Row,
    Col,
} from 'reactstrap';

//Import Files
import AlertService from '../../services/AlertService';
import AuthService from '../../services/AuthService';
import StockDetailsService from '../../services/StockDetailsService';
import Message from './message';

const AddAlert = (props) => {
    const [message, setMessage] = useState(null);
    const [alert, setAlert] = useState({ price: "", message: "", stock: "" });
    const [stockList, setStockList] = useState([]);

    const fetchData = async () => {
        return await StockDetailsService.getAllStocks().then(data => {
            setStockList(data)
        })
    }

    const onChange = e => {
        setAlert({ ...alert, [e.target.name]: e.target.value });
    }

    const resetForm = () => {
        setAlert({ price: "", message: "", stock: "" });
    }

    const onSubmit = e => {
        e.preventDefault();
        AuthService.isAuthenticated().then(data => {
            if (data.isAuthenticated === true) {
                const dataPass = { alert: alert, username: data.user.username };
                AlertService.addAlert(dataPass).then(data => {
                    const { message } = data;
                    setMessage(message);
                    resetForm();
                })
            }
        })
    }

    useEffect(() => { fetchData() }, []);

    return (
        <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">
                <i className="fa fa-plus"> </i>
                <span> Add New Alert</span>
            </CardTitle>
            <CardBody className="">
                <Form onSubmit={onSubmit}>
                    <FormGroup>
                        <Label for="alert-stock-entry">Stock</Label>
                        <Input type="select" defaultValue={'DEFAULT'} onChange={onChange} name="stock" id="alert-stock-entry">
                            <option value="DEFAULT" disabled>Select a stock for alert</option>
                            {stockList && stockList.map((data, index) => {
                                return (
                                    <option key={index} value={data.symbol}>{data.name}</option>
                                )
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="alert-price-entry">Price</Label>
                        <input id="alert-price-entry" type="number" name="price" onChange={onChange} className="form-control" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="alert-message-entry">Message</Label>
                        <input type="textarea" id="alert-message-entry" name="message" onChange={onChange} className="form-control" placeholder="alert message" />
                    </FormGroup>
                    <Row>
                        <Col xs="12" sm="6" md="4" lg="2" xl="2">
                            <Button color="success" className="btn btn-lg btn-success btn-block" type="submit">Add</Button>
                        </Col>
                    </Row>
                </Form>
                {message ? <Message message={message} /> : null}
            </CardBody>
        </Card>
    );
}

export default AddAlert;