import React, { useState, useEffect } from 'react';
import {
    Card,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    Row,
    Col
} from 'reactstrap';
import NewsService from '../../services/NewsService';

const News = () => {
    const [news, setNews] = useState();

    //Get Watchlist items with live data
    //map live data out in cards
    const fetchData = async () => {
        await NewsService.newsData().then(res => {
            setNews(res)
        });
    }

    useEffect(() => { fetchData() }, []);

    return (
        <div>
            <Row className="dashboard-cards">
                {news && news.map((data, index) => {
                    return (
                        <Col xs="12" sm="12" md="6" key={index} className="d-flex align-items-stretch">
                            <Card key={index}>
                                <CardBody key={index}>
                                    <Row key={`${index}row`}>
                                        <Col key={`${index}col1`}>
                                            <CardTitle key={`${index}title`}>{data.headline}</CardTitle>
                                            <CardSubtitle key={`${index}subtitle1`}>{data.source}</CardSubtitle>
                                        </Col>
                                    </Row>
                                    <CardText key={`${index}cardtext1`}>{data.summary}</CardText>
                                    <CardText key={`${index}cardtext2`}><a rel="noreferrer" target="_blank" href={data.url}>To read more click to go to the {data.source} artical</a> </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        </div>
    );
}

export default News;
