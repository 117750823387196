//Import Modules
import React, { useState, useContext, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Label,
    Button,
    Row,
    Col
} from 'reactstrap';

//Import Files
import AuthService from '../../services/AuthService';
import Message from './message';
import { AuthContext } from '../../context/AuthContext';

const Login = (props) => {
    //Declare Varables
    const [user, setUser] = useState({ username: "", password: "" });
    const [message, setMessage] = useState(null);
    const authContext = useContext(AuthContext)

    //Controls Form Changing
    const onChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const onSubmit = e => {
        e.preventDefault();

        window.gtag("event", "login", {
            'method': user.username
        });

        //Checks login detials
        AuthService.login(user)
            .then(data => {
                const { isAuthenticated, user, message } = data;
                if (isAuthenticated) {
                    //right detials - set varables
                    authContext.setUser(user)
                    authContext.setIsAuthenticated(isAuthenticated);
                    props.history.push('/') //send to dashboard
                }
                else {
                    setMessage(message);//Send error message
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        window.gtag("event", "screen_view", {
            'screen_name': 'Login'
        });
    }, []);

    return (
        <div>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="fa fa-user"> </i>
                            <span> Login</span>
                        </CardTitle>
                        <CardBody className="">
                            <Form onSubmit={onSubmit}>
                                <FormGroup className="row">
                                    <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                        <Label for="username-entry">Username</Label>
                                        <input type="text" name="username" id="username-entry" onChange={onChange} className="form-control" placeholder="username" />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                        <Label for="password-entry">Password</Label>
                                        <input type="password" name="password" id="password-entry" onChange={onChange} className="form-control" placeholder="password" />
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                                        <Button color="success" className="btn btn-lg btn-success btn-block" type="submit">Login</Button>
                                    </Col>
                                </Row>
                            </Form>
                            {message ? <Message message={message} /> : null}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Login;