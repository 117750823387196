import $ from 'jquery';

export default {
    getStockDetails: (symbol) => {
        return new Promise(function (resolve, reject) {
            $.ajax({
                cache: true,
                type: "Get",
                url: "/StockData/Details",
                data: { symbol: symbol },
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: (res) => resolve(res),
                error: (err) => reject(err)
            });
        });
    },
    getAllStocks: () => {
        return new Promise(function (resolve, reject) {
            $.ajax({
                cache: false,
                type: "Get",
                url: "/StockData/search",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: (res) => resolve(res),
                error: (err) => reject(err)
            });
        });
    },
    itemInWatchlist: (data) => {
        return new Promise(function (resolve, reject) {
            $.ajax({
                cache: true,
                type: "Get",
                url: "/Stock/inWatchlist",
                data: data,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: (res) => resolve(res),
                error: (err) => reject(err)
            });
        });
    },
    addWatchList: (id) => {
        // return new Promise(function (resolve, reject) {
        //     $.ajax({
        //         cache: false,
        //         type: "Post",
        //         url: "/Stock/add",
        //         data: JSON.stringify(id),
        //         contentType: "application/json; charset=utf-8",
        //         dataType: "json",
        //         //success: (res) => resolve(res),
        //         error: (err) => reject(err)
        //     });
        // }); 
        return fetch('/Stock/add', {
            method: "post",
            body: JSON.stringify(id),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => data);
    },
    removeWatchList: (id) => {
        // return new Promise(function (resolve, reject) {
        //     $.ajax({
        //         cache: false,
        //         type: "Post",
        //         url: "/Stock/remove",
        //         data: JSON.stringify(id),
        //         contentType: "application/json; charset=utf-8",
        //         dataType: "json",
        //         //success: (res) => resolve(res),
        //         error: (err) => reject(err)
        //     });
        // }); 
        return fetch('/Stock/remove', {
            method: "post",
            body: JSON.stringify(id),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(data => data);
    },
    search: () => {
        // return new Promise(function (resolve, reject) {
        //     $.ajax({
        //         cache: false,
        //         type: "Get",
        //         url: "/StockData/",
        //         contentType: "application/json; charset=utf-8",
        //         dataType: "json",
        //         success: (res) => resolve(res),
        //         error: (err) => reject(err)
        //     });
        // });
        return fetch('/StockData/search')
            .then(res => res.json())
            .then(data => { return data })
    },
}