//Import Modules
import React, { useEffect } from 'react';
import {
    Row,
    Col,
} from 'reactstrap';

//Import Files
import AddAlert from './alertsAdd';
import CurrentAlerts from './alertsCurrent';

const Alerts = () => {

    useEffect(() => {
        window.gtag("event", "screen_view", {
            'screen_name': 'Alerts'
        });
    }, []);

    return (
        <div>
            <Row>
                <Col>
                    <AddAlert />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CurrentAlerts />
                </Col>
            </Row>
        </div>
    );
}

export default Alerts;