//Import Modules
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Label,
    Button,
    Row,
    Col
} from 'reactstrap';

//Import Files
import AdminService from '../../services/AdminService';
import AdminRegister from './adminRegister';
import Message from './message';

const Admin = (props) => {
    const [message, setMessage] = useState(null);
    const [stock, setstock] = useState({ name: "", symbol: "" });

    const onChange = e => {
        setstock({ ...stock, [e.target.name]: e.target.value });
    }

    const resetForm = () => {
        setstock({ name: "", symbol: "" });
    }

    const onSubmit = e => {
        e.preventDefault();
        //Checks login detials
        AdminService.addStock(stock).then(data => {
            const { message } = data;
            setMessage(message);
            resetForm();
        })
    }

    useEffect(() => {
        window.gtag("event", "screen_view", {
            'screen_name': 'Admin'
        });
    }, []);

    return (
        <>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="fa fa-plus-square"> </i>
                            <span> Add New Stock</span>
                        </CardTitle>
                        <CardBody className="">
                            <Form onSubmit={onSubmit}>
                                <FormGroup>
                                    <Label for="stock-name-entry">Stock Name</Label>
                                    <input type="text" id="stock-name-entry" name="name" onChange={onChange} className="form-control" placeholder="stock name" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="stock-symbol-entry">Stock Symbol</Label>
                                    <input type="text" id="stock-symbol-entry" name="symbol" onChange={onChange} className="form-control" placeholder="stock symbol" />
                                </FormGroup>
                                <Row>
                                    <Col xs="12" sm="6" md="4" lg="2" xl="2">
                                        <Button color="success" className="btn btn-lg btn-success btn-block" type="submit">Add</Button>
                                    </Col>
                                </Row>
                            </Form>
                            {message ? <Message message={message} /> : null}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <AdminRegister />
                </Col>
            </Row>
        </>
    );
}

export default Admin;