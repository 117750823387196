import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar'

import { AuthContext } from '../../context/AuthContext';
import AuthService from '../../services/AuthService';

const Sidebar = (props) => {
    const { isAuthenticated, user, setIsAuthenticated, setUser } = useContext(AuthContext);

    useEffect(() => {
        // if() {
        //     AuthService.logout().then(data => {
        //         setUser(data.user)
        //         setIsAuthenticated(false);
        //     });
        //     props.history.push('/') //send to dashboard
        // }
        console.log(user)
    })

    /*--------------------------------------------------------------------------------*/
    /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
    /*--------------------------------------------------------------------------------*/
    // const expandLogo = () => {
    //     document.getElementById("logobg").classList.toggle("expand-logo");
    // }
    /*--------------------------------------------------------------------------------*/
    /*Verifies if routeName is the one active (in browser input)                      */
    /*--------------------------------------------------------------------------------*/

    const activeRoute = (routeName) => {
        return props.location.pathname.indexOf(routeName) > -1 ? 'selected' : '';
    }

    return (
        // <aside className="left-sidebar" id="sidebarbg" data-sidebarbg="skin6" onMouseEnter={expandLogo.bind(null)} onMouseLeave={expandLogo.bind(null)}>
        <aside className="left-sidebar" id="sidebarbg" data-sidebarbg="skin6">
            <div className="scroll-sidebar">
                <PerfectScrollbar className="sidebar-nav">
                    <Nav id="sidebarnav">
                        {props.routes.map((prop, key) => {
                            if (prop.redirect) {
                                return null;
                            }
                            else {
                                if (prop.auth === 'all') {
                                    return (
                                        <li className={activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                            <NavLink to={prop.path} className="sidebar-link" activeClassName="active" alt={prop.name}>
                                                <i className={prop.icon} />
                                                <span className="sidebar-items hide-menu">{prop.name}</span>
                                            </NavLink>
                                        </li>
                                    );
                                }
                                if (isAuthenticated === false && prop.auth === 'none') {
                                    return (
                                        <li className={activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                            <NavLink to={prop.path} className="sidebar-link" activeClassName="active" alt={prop.name}>
                                                <i className={prop.icon} />
                                                <span className="sidebar-items hide-menu">{prop.name}</span>
                                            </NavLink>
                                        </li>
                                    );
                                }
                                if (isAuthenticated === true && prop.auth === 'user') {
                                    return (
                                        <li className={activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                            <NavLink to={prop.path} className="sidebar-link" activeClassName="active" alt={prop.name}>
                                                <i className={prop.icon} />
                                                <span className="sidebar-items hide-menu">{prop.name}</span>
                                            </NavLink>
                                        </li>
                                    );
                                }
                                if (isAuthenticated === true && user.role === "admin") {
                                    return (
                                        <li className={activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                            <NavLink to={prop.path} className="sidebar-link" activeClassName="active" alt={prop.name}>
                                                <i className={prop.icon} />
                                                <span className="sidebar-items hide-menu">{prop.name}</span>
                                            </NavLink>
                                        </li>
                                    );
                                }
                                if (isAuthenticated === true && prop.auth === 'loggedin') {
                                    return (
                                        <li className={activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
                                            <NavLink to={prop.path} className="sidebar-link" activeClassName="active" alt={prop.name}>
                                                <i className={prop.icon} />
                                                <span className="sidebar-items hide-menu">{prop.name}</span>
                                            </NavLink>
                                        </li>
                                    );
                                }
                            }
                        })}
                    </Nav>
                </PerfectScrollbar>
            </div>
        </aside>
    );
}
export default Sidebar;