//Import Modules
import React, { useState, useRef, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Label,
    Button,
    Row,
    Col
} from 'reactstrap';

//Import Files
import AuthService from '../../services/AuthService';
import Message from './message';

const Register = (props) => {
    //Declare Varables
    const [user, setUser] = useState({ username: "", password: "" });
    const [message, setMessage] = useState(null);
    let timerID = useRef(null)

    useEffect(() => {

        window.gtag("event", "screen_view", {
            'screen_name': 'Register'
        });

        return () => {
            clearTimeout(timerID);
        }
    }, []);

    //Controls Form Changing
    const onChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const resetForm = () => {
        setUser({ username: "", password: "" });
    }

    const onSubmit = e => {
        e.preventDefault();

        window.gtag("event", "sign_up", {
            'method': user.username
        });

        //Checks login detials
        AuthService.register(user).then(data => {
            const { message } = data;
            setMessage(message);
            resetForm();
            if (!message.msgError) {
                timerID = setTimeout(() => {
                    props.history.push('/login');
                }, 2000)
            }
        })
    }

    return (
        <div>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="fa fa-user-plus"> </i>
                            <span> Register</span>
                        </CardTitle>
                        <CardBody >
                            <Form onSubmit={onSubmit}>
                                <FormGroup className="row">
                                    <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                        <Label for="username-entry">Username</Label>
                                        <input type="text" name="username" id="username-entry" onChange={onChange} value={user.username} className="form-control" placeholder="username" />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row">
                                    <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                        <Label for="password-entry">Password</Label>
                                        <input type="password" name="password" id="password-entry" onChange={onChange} value={user.password} className="form-control" placeholder="password" />
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                                        <Button color="success" className="btn btn-lg btn-success btn-block" type="submit">Register</Button>
                                    </Col>
                                </Row>
                            </Form>
                            {message ? <Message message={message} /> : null}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Register;