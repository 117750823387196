//Import Modules
import React, { useContext, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    Button,
    Row,
    Col
} from 'reactstrap';

//Import Files
import AuthService from '../../services/AuthService';
import { AuthContext } from '../../context/AuthContext';

const Logout = (props) => {
    const { setIsAuthenticated, setUser } = useContext(AuthContext);

    const onSubmitLogout = () => {
		AuthService.logout().then(data => {
			setUser(data.user)
			setIsAuthenticated(false);
		});
        props.history.push('/') //send to dashboard
	}

    useEffect(() => {
        window.gtag("event", "screen_view", {
            'screen_name': 'Logout'
        });
    }, []);

    return (
        <div>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="fa fa-times-circle"> </i>
                            <span> Logout</span>
                        </CardTitle>
                        <CardBody className="">
                            <Form onSubmit={onSubmitLogout}>
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="2" xl="2">
                                        <Button color="danger" className="btn btn-lg btn-block" type="submit">Logout</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Logout;