import React from 'react';
import { Link } from 'react-router-dom';

const SearchList = ({ stockList = [] }) => {
    return (
        <>
            { stockList.map((data, index) => {
                if (data) {
                    return (
                        <Link key={data.id+'link'} to={{ pathname: `/details/${data.symbol}`, data: data.symbol }}>
                            <div key={data.id}>
                                <h4>{data.name}</h4>
                            </div>
                        </Link>
                    )
                }
                return null
            })}
        </>
    );
}

export default SearchList