const publicVapidKey = 'BKB5ERVVl2nnTBPIRwZi_jmdhW7phb10fqS3Asad1guqRiN_xlpE9yI0N_dAMPgl364hBJBugOVf-4EyZ8ymxfQ';

//Check for ServiceWorker
if ('serviceWorker' in navigator) {
    send().catch(err => console.error(err));
}

//Register ServiceWorker, register push, send push
async function send() {
    //Register ServiceWorker
    console.log('Registering ServiceWorker...');
    const register = await navigator.serviceWorker.register('./service-worker.js', {
        scope: '/'
    });
    console.log('ServiceWorker registered...');

    //Register Push
    console.log('Registering push...');
    const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    });
    console.log('Push registered...');

    //Send Push Notifaction
    console.log('Sending Push...');
    await fetch('/Notifaction/subscribe', {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
            'content-type': 'application/json'
        }
    });
    console.log('Push Sent...');
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }