import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Table,
    Card,
    CardTitle,
    CardBody
} from 'reactstrap';
import LiveDataService from '../../services/LiveDataService';

const Trends = () => {
    const [stocks, setStocks] = useState();
    const [bool, setBool] = useState(false);

    // const timer = ms => new Promise(res => setTimeout(res, ms))

    const fetchData = async () => {
        await LiveDataService.allLiveData().then(res => {
            setStocks(res.stocks)
        }).catch(err => console.log(err))
    }

    // const newFunc = async () => {
    //     if(bool === false) {
    //         fetchData()
    //         setBool(true)
    //     }
    //     else {
    //         //await timer(20000);
    //         //setInterval(fetchData, 20000);
    //         //fetchData(); 
    //     }
        
        
    // }

    
    // newFunc();

    useEffect(() => {
        console.log(bool)
        console.log('func')
        if(bool === false) {
            console.log('fal')
            fetchData()
            setBool(true)
            setInterval(fetchData, 20000);
        }
        else {
            setInterval(fetchData, 20000);
        }
    }, [])

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col md="12">
                        <Row>
                            <Col>
                                <CardTitle>Top Performers</CardTitle>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table className="table-responsive-sm" size="sm" striped>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>&nbsp;&nbsp;Stock</th>
                                            <th className="text-right">Price</th>
                                            <th className="text-right">Change (£)</th>
                                            <th className="text-right">Percentage Change (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stocks && stocks.map((data, index) => {

                                            var companyImage = '';

                                            if (data.logo !== '') {
                                                companyImage = <img width="16px" src={data.logo} alt="logo" className="img-fluid float-left" />;
                                            }
                                            else {
                                                companyImage = '';
                                            }
                                            var count = index + 1;
                                            return (
                                                <tr key={index}>
                                                    <th>{count}</th>
                                                    <td>{companyImage}&nbsp;&nbsp;{data.symbol}</td>
                                                    <td className="text-right">£{data.liveData.priceCurrent}</td>
                                                    <td className="text-right">{data.liveData.priceChange}</td>
                                                    <td className="text-right">{data.liveData.pricePercentChange}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col md="6">
                        <Row>
                            <Col>
                                <CardTitle>IPO Calender</CardTitle>
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
            </CardBody>
        </Card>
    );
}

export default Trends;
