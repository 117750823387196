import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import indexRoutes from './App.js';
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom'
import AuthProvider from './context/AuthContext';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './client';

import 'jquery';
import 'react-popper';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.css';
import './assets/scss/myStyle.css';

ReactDOM.render(
    <AuthProvider>
      <BrowserRouter>

        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route path={prop.path} key={key} component={prop.component} />;
          })}
        </Switch>

      </BrowserRouter>
    </AuthProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

reportWebVitals();