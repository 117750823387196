//Import Modules
import React, { useEffect } from 'react';
import {
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	CardText,
	Row,
	Col
} from 'reactstrap';

//Import Files
import LaptopInstall from '../../assets/images/laptop_install.png';
import PhoneInstall1 from '../../assets/images/phone_install_1.png';
import PhoneInstall2 from '../../assets/images/phone_install_2.png';

const Info = () => {

	useEffect(() => {
        window.gtag("event", "screen_view", {
            'screen_name': 'Info'
        });
    }, []);

	return (
		<div>
			<Row>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					<Card>
						<CardTitle className="bg-light border-bottom p-3 mb-0">
							<i className="fa fa-info-circle"> </i>
							<span> Info</span>
						</CardTitle>
						<CardBody className="">
							<Row>
								<Col>
									<CardTitle>About TheStockTracker</CardTitle>
									<hr />
								</Col>
							</Row>
							<Row>
								<Col>
									<CardText>TheStockTracker provides information on the stock market. It is an easy to use tracker for beginners
										with an interest in the stock market. TheStockTracker has all the tools you need to share and view
										trades with real-time data and browser-based charts that let you do your research from anywhere!</CardText>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<CardSubtitle>Feature List:</CardSubtitle>
								</Col>
							</Row>
							<Row>
								<Col>
									<CardText>
										<ul>
											<li>In-app price alerts</li>
											<li>Market change push notifications</li>
											<li>Watchlist tracking</li>
											<li>Company details</li>
											<li>20 year historical data</li>
										</ul>
									</CardText>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<CardTitle>Progressive Web App (PWA)</CardTitle>
									<hr />
								</Col>
							</Row>
							<Row>
								<Col>
									<CardSubtitle>Technical Info</CardSubtitle>
								</Col>
							</Row>
							<Row>
								<Col>
									<CardText>The app is a Progressive Web App (PWA) giving it many advantages. The app is installable to the
										local device giving it the ability to send push notifactions and work when you have no internet
										access.</CardText>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<CardSubtitle>Feature List:</CardSubtitle>
								</Col>
							</Row>
							<Row>
								<Col>
									<CardText>
										<ul>
											<li>Installable</li>
											<li>Offline content</li>
											<li>Security</li>
										</ul>
									</CardText>
								</Col>
							</Row>

							<Row className="mt-3">
								<Col>
									<CardTitle>Installable</CardTitle>
									<hr />
								</Col>
							</Row>
							<Row>
								<Col>
									<CardSubtitle>Follow these steps to install the app on any device.</CardSubtitle>
								</Col>
							</Row>
							<Row>
								<Col>
									<CardText>
										<ol>
											<li>Navigate to the install option in the browser</li>
											<li>Install the app</li>
										</ol>
									</CardText>
								</Col>
							</Row>
							<Row>
								<Col md="6">
									<img className="img-fluid" width="250px" src={PhoneInstall1} alt="PhoneInstall" />
								</Col>
								<Col md="6">
									<img className="img-fluid mt-2" width="350px" src={PhoneInstall2} alt="PhoneInstall" />
									<img className="img-fluid mt-2" width="350px" src={LaptopInstall} alt="LaptopInstall" />
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					<Card>
						<CardTitle className="bg-light border-bottom p-3 mb-0">
							<i className="fa fa-paper-plane"> </i>
							<span> Contact</span>
						</CardTitle>
						<CardBody className="">
							<Row>
								<Col>
									<CardTitle>Get in touch!</CardTitle>
									<hr />
								</Col>
							</Row>
							<Row>
								<Col>
									<CardSubtitle>Email</CardSubtitle>
								</Col>
							</Row>
							<Row>
								<Col>
									<CardText>Email us by clicking <a href="mailto:contact@cieranhughes.co.uk?subject=TheStockTracker Contact">here</a>, or using contact@cieranhughes.co.uk</CardText>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col>
									<CardSubtitle>Website</CardSubtitle>
								</Col>
							</Row>
							<Row>
								<Col>
									<CardText>View more on the developer at <a href="https://cieranhughes.co.uk" target="_blank" rel="noopener noreferrer">cieranhughes.co.uk</a></CardText>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

export default Info;