//Import Modules
import React, { useState, useEffect, useContext } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Row,
    Col,
    Form,
    Button
} from 'reactstrap';

//Import Files
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import { AuthContext } from '../../context/AuthContext';

const Account = (props) => {
    const [watchlist, setWatchlist] = useState();
    const { user, setIsAuthenticated, setUser } = useContext(AuthContext);


    const fetchData = async () => {
        await AuthService.isAuthenticated().then(data => {
            if (data.isAuthenticated === true) {
                const dataPass = { username: data.user.username };
                UserService.watchlist(dataPass).then(res => {
                    setWatchlist(res.stocks)
                })
            }
        })
    }

    const onSubmitDeleteAccount = e => {
        e.preventDefault();
        const answer = window.confirm('Are you sure you want to delete your account? \n There is no going back!')
        if (answer === true) {
            AuthService.logoutDeleteAccount().then(data => {
                setUser(data.user)
                setIsAuthenticated(false);
            });
            props.history.push('/') //send to dashboard
        }
    }

    useEffect(() => {

        window.gtag("event", "screen_view", {
            'screen_name': 'Account'
        });

        fetchData()
    }, []);

    return (
        <div>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="fa fa-user"> </i>
                            <span> Account</span>
                        </CardTitle>
                        <CardBody className="">
                            <Row>
                                <Col>
                                    <CardSubtitle>Personal Details</CardSubtitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CardText>Username: {user.username}</CardText>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <CardSubtitle>Details</CardSubtitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CardText>Watchlist Items: </CardText>
                                    <CardText>
                                        {watchlist && watchlist.map((data, index) => {
                                            return (
                                                <li key={index}>{data.name}</li>
                                            )
                                        })}
                                    </CardText>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="fa fa-cog"> </i>
                            <span> Account Settings</span>
                        </CardTitle>
                        <CardBody className="">
                            <Row>
                                <Col>
                                    <CardTitle>Delete Account</CardTitle>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CardText>This will delete all account data from the system. There is no going back from this.</CardText>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form onSubmit={onSubmitDeleteAccount}>
                                        <Row>
                                            <Col xs="12" sm="12" md="4" lg="3" xl="2" className="pt-2">
                                                <Button color="danger" className="btn btn-block" type="submit">Delete</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Account;